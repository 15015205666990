
export default [
    {
        title: 'Emp Code',
        key: 'EmpCode',
        minWidth: 100,
    },
	{
        title: 'Emp Name',
        key: 'NAME',
        minWidth: 100,
    },
    {
        title: 'Shift',
        key: 'Shift',
        minWidth: 100,
    },
    {
        title: 'Time(In)',
        key: 'timein',
        minWidth: 100,
    },
    {
        title: 'Time(Out)',
        key: 'timeout',
        minWidth: 100,
    },   
]
