<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Manage Red Cross" slot="title" link="/helpContent/Manage Red Cross" />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <FormSelect label="Company" :items="companyList" item-name="Name" item-value="id" v-model="filtering.selectedCompany" rules="required"/>
        </div>
        <div class="col-sm-3">
          <FormSelect label="Staff" :items="staffList" item-name="Name" item-value="id" v-model="filtering.selectedStaff" rules="required"/>
        </div>
        <div class="col-sm-2">
          <inputDate label="Date" v-model="filtering.date" rules="required" />
        </div>
        <div class="col-sm-4">
          <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
            <Icon type="ios-search" />
          </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="redcrossReport"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-sort-change="sortChange">
    </DataTable>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import {getStaffAttendanceByDate, getAllStaffByHRorManager, getAllCompanyList,} from "../api";
import tableConfig from "./table";
import { ValidationObserver } from "vee-validate";
import InputDate from "Components/form/InputDate";
export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
    InputDate,
  },
  data() {
    return {
      filtering: {
        selectedStaff: "",
        selectedCompany: "",
        date: "",
      },
      staffList: [],
      companyList: [],
      sorting: {
        key: "NAME",
        order: "asc",
      },
   
      tableConfig,
      redcrossReport: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
     this.getAllList();
  },
  methods: {
    async getAllList() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
      });
      getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    search() {
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          setTimeout(() => {
            this.$refs.validator.validate().then((result) => {
              if (result) {
                this.showLoader();
                const { key, order } = this.sorting;
                const filters = {};
                if (this.filtering.selectedStaff) {
                  filters.selectedStaff = this.filtering.selectedStaff;
                }
                if (this.filtering.selectedCompany) {
                  filters.selectedCompany = this.filtering.selectedCompany;
                }
                if (this.filtering.date) {
                  filters.date = this.filtering.date;
                }
                const data = {
                  filterjson: {
                    filter: [filters],
                    sort: [
                      {
                        key,
                        order,
                      },
                    ],
                  },
                };
                getStaffAttendanceByDate(data)
                  .then(this.handleResponse)
                  .catch(this.handleError);
              }
            });
          }, 50);
        }
      });
    },
    handleResponse(response) {
      this.hideLoader();
      this.redcrossReport = response.data;
      this.buttonToShow = this.filtering.statusSelected;
    },
  },
};
</script>
